import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'
import Layout from '../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

const thankYouNewsletterConfirmation = () => {
  const data = useStaticQuery(graphql`
    query NewsletterConfirmationSEO {
      allContentfulPages(
        filter: {
          pageReference: { pageIdentifier: { eq: "NewsletterConfirmation" } }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node
  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <section className="pt-5 mt-5 pb-5">
        <div className="container-1">
          <div className="row mt-3">
            <div className="col-md-8 offset-2 text-center pt-0">
              <StaticImage
                className="img-fluid w-50 mx-auto d-block"
                src="../resources/images/vectors/undraw_message_sent_1030.svg"
                placeholder="tracedSVG"
                alt="Lenses newsletter subscription"
              />
              <h1 className="mt-3">Almost there...</h1>
              <p className="hero-description-dark">
                Please check your email (including your spam) to complete your
                registration and start getting our updates
              </p>
            </div>
            <div className="col-md-8 col-12 offset-md-2 pt-0">
              <p className="text-center">
                <small>
                  Didn&apos;t get the email? Contact{' '}
                  <a
                    className="link-text"
                    href="mailto:info@lenses.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    info@lenses.io
                  </a>
                </small>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default thankYouNewsletterConfirmation
